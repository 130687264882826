export default function AlertIcon() {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.25039 10.25C6.25039 10.4489 6.32941 10.6397 6.47006 10.7803C6.61072 10.921 6.80148 11 7.00039 11C7.19931 11 7.39007 10.921 7.53072 10.7803C7.67138 10.6397 7.75039 10.4489 7.75039 10.25C7.75039 10.0511 7.67138 9.86032 7.53072 9.71967C7.39007 9.57902 7.19931 9.5 7.00039 9.5C6.80148 9.5 6.61072 9.57902 6.47006 9.71967C6.32941 9.86032 6.25039 10.0511 6.25039 10.25ZM6.50039 5.5V8.375C6.50039 8.44375 6.55664 8.5 6.62539 8.5H7.37539C7.44414 8.5 7.50039 8.44375 7.50039 8.375V5.5C7.50039 5.43125 7.44414 5.375 7.37539 5.375H6.62539C6.55664 5.375 6.50039 5.43125 6.50039 5.5ZM13.9332 12.375L7.43321 1.125C7.33633 0.957813 7.16915 0.875 7.00039 0.875C6.83165 0.875 6.66289 0.957813 6.56758 1.125L0.0675834 12.375C-0.124604 12.7094 0.116021 13.125 0.500396 13.125H13.5004C13.8848 13.125 14.1254 12.7094 13.9332 12.375ZM1.69102 11.9391L7.00039 2.74844L12.3098 11.9391H1.69102Z"
        fill="#FF9900"
      />
    </svg>
  );
}
