import { Button, Col, Form, Input, Row } from 'antd';
import { columns } from 'src/Assets/Content/Customers/data';
import SearchIcon from 'src/Assets/Icons/SearchIcon';
import { SearchFormWraper, TableWraper } from 'src/Styles/global';
import { Pagination, Table } from 'antd';
import { useEffect, useState } from 'react';
import Previous from 'src/Assets/Icons/Previous';
import Next from 'src/Assets/Icons/Next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  CustomerAction,
  SortCustomerAction,
  SearchCustomerAction,
} from 'src/Redux/App/Actions/Auth';
import { useSelector } from 'react-redux';
import {
  users,
  loadingForUser,
  setPagination,
  setSorting,
  pagination,
  sorting,
  totalUser,
  userCurrentPage,
  resetUserCurrentPage,
} from '../../Redux/App/index';
import { AppRoutes } from 'src/Components/Constant/routes';
import { orderBy } from 'lodash';

const Customers = () => {
  const userList: any = useSelector(users);
  const pag: any = useSelector(pagination);
  const sort: any = useSelector(sorting);
  const loadingState = useSelector(loadingForUser);
  const currentUserPage: any = useSelector(userCurrentPage);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState<number>(currentUserPage);
  const [pageSize, setPageSize] = useState<number>(25);
  const [keyword, setKeword] = useState('');
  const [columnData, setColumnData] = useState([]);
  const getRowClassName = (record: any, index: number) => {
    return index % 2 === 0
      ? 'table-row-White-customer'
      : 'table-row-lite-customer';
  };

  const handlePageChange = (page: number, pageSize?: number) => {
    dispatch(setPagination({ page: page, pageSize: pageSize }));
    setCurrentPage(page);
    if (pageSize) {
      setPageSize(pageSize);
      const key = sort?.key ? sort?.key : '';
      const order = sort?.order ? sort?.order : '';
      dispatch(resetUserCurrentPage(page));
      dispatch<any>(
        SortCustomerAction({ key, order, keyword, pageSize, page })
      );
    }
  };

  const handleSizeChange = (current: number, size: number) => {
    setCurrentPage(1);
    setPageSize(size);
    const key = sort?.key ? sort?.key : '';
    const order = sort?.order ? sort?.order : '';
    dispatch<any>(
      SortCustomerAction({
        key,
        order,
        keyword,
        pageSize: size,
        page: currentPage,
      })
    );
  };

  const searchUser = () => {
    const key = sort?.key ? sort?.key : '';
    const order = sort?.key ? sort?.key : '';
    setPageSize(25);
    dispatch<any>(
      SortCustomerAction({ key, order, keyword, pageSize: 25, page: 1 })
    );
  };
  useEffect(() => {
    if (sort?.key) {
      const columnsWithSortOrder: any = columns.map((col: any) => ({
        ...col,
        sorter: col.sorter || false, // Ensure sorter function is applied
        sortOrder: sort.key === col.key ? sort.order : null, // Apply the correct sortOrder for the header
        defaultSortOrder: sort.key === col.key ? sort.order : null, // Show the sorting indicator for the default column
      }));
      setColumnData(columnsWithSortOrder);
      getPaginatedUserList(sort.key, sort.order);
      if (pag?.pageSize) {
        setPageSize(pag?.pageSize);
      }
    } else if (pag?.pageSize) {
      setPageSize(pag?.pageSize);
    } else {
      const key = '';
      const order = 'ASC';
      const keyword = '';
      const pageSize = 25;
      dispatch<any>(
        SortCustomerAction({ key, order, keyword, pageSize, page: currentPage })
      );
    }
  }, []);

  const totalUserCount = useSelector(totalUser);
  const totalItems: any = totalUserCount;
  const indexOfLastItem: any = currentPage * pageSize;
  const indexOfFirstItem = indexOfLastItem - pageSize;
  const currentItems = userList;
  let dynamicPageSizeOptions = ['25', '50', '100'];

  useEffect(() => {
    console.log(users, 'users');
  }, [users]);

  const getPaginatedUserList = (key: any, order: any) => {
    dispatch<any>(
      SortCustomerAction({ key, order, keyword, pageSize: 25, page: 1 })
    );
  };

  const handleColumnHeaderClick = (key: any, order: any) => {
    const updatedColumns: any = columns.map((col: any) => ({
      ...col,
      sortOrder: col.key === key ? order : null,
      defaultSortOrder: col.key === key ? order : null,
    }));
    setColumnData(updatedColumns);
    dispatch(setSorting({ key: key, order: order }));
    dispatch<any>(
      SortCustomerAction({ key, order, keyword, pageSize, page: currentPage })
    );
  };
  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    console.log(sorter, 'sorter');
    if (sorter.order === undefined) {
      let key = "registerDate";
      let order = 'descend';
      const updatedColumns: any = columns.map((col: any) => ({
        ...col,
        sortOrder:  null,
        defaultSortOrder:  null,
      }));
      setColumnData(updatedColumns);
      dispatch<any>(
        SortCustomerAction({
          key,
          order,
          keyword,
          pageSize,
          page: currentPage,
        })
      );
      // handleColumnHeaderClick(sorter?.columnKey, 'ASC'); // Pass the column key and sort order to your click handler function
    } else {
      if (sorter && sorter.columnKey) {
        handleColumnHeaderClick(sorter.columnKey, sorter.order); // Pass the column key and sort order to your click handler function
      }
    }
  };

  const handleRowClick = (record: any) => {
    navigate(AppRoutes.customer, { state: { record } });
  };
  return (
    <>
      <SearchFormWraper>
        <Form
          layout='horizontal'
          // onFinishFailed={onFinishFailed}
          // onFinish={onFinish}
        >
          <Row
            style={{ marginBottom: '12px' }}
            justify={'end'}
          >
            <Col
              md={7}
              style={{ display: 'flex' }}
            >
              <Input
                type='search'
                placeholder='Search...'
                prefix={<SearchIcon />}
                style={{ marginRight: 8 }}
                onChange={(e) => setKeword(e.target.value)}
              />
              <Button
                onClick={searchUser}
                className='search-butn'
                htmlType='submit'
              >
                Search
              </Button>
            </Col>
          </Row>
        </Form>
      </SearchFormWraper>
      <TableWraper>
        <Table
          dataSource={currentItems}
          columns={columnData.length > 0 ? columnData : columns}
          rowClassName={getRowClassName}
          pagination={false}
          loading={loadingState}
          onChange={handleTableChange}
          onRow={(record, rowIndex) => {
            return {
              onClick: () => handleRowClick(record),
            };
          }}
        />
        <div>
          {totalItems > 10 ? (
            <Pagination
              pageSize={pageSize}
              total={totalItems}
              current={currentUserPage}
              showSizeChanger={true}
              showTotal={(total) => `Result ${pageSize} out of ${total}`}
              onChange={handlePageChange}
              onShowSizeChange={handleSizeChange}
              prevIcon={<Previous disabled={currentPage === 1} />}
              nextIcon={<Next disabled={indexOfLastItem >= totalItems} />}
              pageSizeOptions={dynamicPageSizeOptions}
              itemRender={(page, type, originalElement) => {
                if (type === 'page') {
                  return <span>{page}</span>;
                }
                return originalElement;
              }}
            />
          ) : null}
        </div>
      </TableWraper>
    </>
  );
};

export default Customers;
